import React from 'react';
import Inicio from './componentsMC/Inicio'
import Movimientos from './componentsMC/Movimientos'
import Banco from './componentsMC/Banco'
import Informacion from './componentsMC/Informacion'
import Empleados from './componentsMC/Empleados'
import { useAuth } from '../LoginContext';
import Proveedores from './componentsMC/Proveedores';

const MainContent = ({ selectedOption ,empleado, nivel }) => {

  const { user } = useAuth();
  const userId = user.uid;

  return (
    <div className="main-content">
      {selectedOption  === 'Inicio' && (
        <Inicio userId={userId}/>
      )}
       {selectedOption  === 'Ingresos' && (
        <Movimientos userId={userId} tipo="Ingresos" nivel={nivel} />
      )}
      {selectedOption  === 'Egresos' && (
        <Movimientos userId={userId} tipo="Egresos" nivel={nivel}/>
      )}
      {selectedOption  === 'Banco' && (
        <Banco userId={userId} />
      )}
      {selectedOption  === 'Empleados' && (
        <Empleados nivel={nivel} empleado={empleado} userId={userId}/>
      )}
      {selectedOption  === 'Proveedores' && (
        <Proveedores nivel={nivel}  userId={userId}/>
      )}
      {selectedOption  === 'InfoCliente' && (
        <Informacion userId={userId}/>
      )}

      
    </div>
  );
};

export default MainContent;